// custom variables and override bootstrap variables
//== Scaffolding
$white: #fff;
$red: #de221e;
$body-bg: $white;
$text-color: #231f20 !default;

//== Typography
$font-family-sans-serif: 'Tradegothic', Helvetica, Arial, sans-serif !default;

$headings-color: #231f20 !default;
$headings-font-family: "Figtree", sans-serif !default;


//== Buttons
$btn-primary-color: #fff !default;
$btn-primary-bg: #de221e !default;
$btn-primary-border: #de221e !default;

$btn-info-color: #de221e !default;
$btn-info-bg: transparent !default;
$btn-info-border: #de221e !default;



//== Grid system
$grid-gutter-width: 20px !default;


//== Container sizes
// Large screen / wide desktop
$container-large-desktop: (1180px + $grid-gutter-width) !default;
$screen-lg: 1300px !default;